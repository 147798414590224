import { MutableRefObject, useEffect, useState } from "react";

export function useFactSetEvent(widgetRef: MutableRefObject<any>, eventName: string, callback?: Function) {
    useEffect(() => {
        const widget = widgetRef.current;
        if (widget) {
            if (callback) {
                widget.addEventListener(eventName, callback);
            }
        }

        return () => {
            if (widget) {
                if (callback) {
                    widget.removeEventListener(eventName, callback);
                }
            }
        }
    }, [callback, eventName, widgetRef]);
}

export function useFactSetWidget(widgetRef: MutableRefObject<any>) {
    const [wigetState, setWidgetState] = useState({
        isFatal: false,
        isError: false,
        isPending: true
    });
    useFactSetEvent(widgetRef, "store-fatal", () => setWidgetState(curr => ({
        ...curr,
        isFatal: true,
        isPending: false
    })));
    useFactSetEvent(widgetRef, "store-error", () => setWidgetState(curr => ({
        ...curr,
        isError: true,
        isPending: false
    })));
    useFactSetEvent(widgetRef, "data-load", () => setWidgetState(curr => ({
        ...curr,
        isPending: false
    })));
    return wigetState;
}