import { useRef } from "react";
import { useFactSetEvent } from "./use-factset-event";

type SymbolSearchResultClickEvent = {
    detail: [{
        identifier: string;
    }]
}

export type SymbolSearchProps = {
    onSearchResultClick?: (event: SymbolSearchResultClickEvent) => void;
};

export function SymbolSearch({ onSearchResultClick }: SymbolSearchProps) {
    const widgetRef = useRef<any>(null);
    useFactSetEvent(widgetRef, "select-result", onSearchResultClick);
    return (<fds-search-suggester ref={widgetRef} />);
}