"use client";

import { HeaderWithSlots } from "#ui/header";
import { LinkButton } from "#ui/link";
import { Logo } from "#ui/logo";
import { NavSheet } from "#ui/nav";
import { NavAccordion } from "#ui/nav-accordion";
import { NavMenu } from "#ui/nav-menu";
import NextLink from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { SymbolSearch } from "../market-research/symbol-search";
import { LinkDocumentsReceived } from "./link-documents-received";
import { PopoverProfileAndSettings } from "./popover-profile-and-settings";
import { useNavRoutes } from "./use-nav-routes";

export function Header() {
  const activePath = usePathname();
  const routes = useNavRoutes();
  const router = useRouter();

  return (
    <>
      <HeaderWithSlots
        layout="mobileOnly"
        className="xl:hidden"
        slot0={
          <NextLink href={routes.Home.path!}>
            {/* TODO: Convert dual logos to one responsive logo */}
            <Logo layout="mark-only" size="xs" className="sm:hidden" />
            <Logo layout="mark-and-word" size="xs" className="max-sm:hidden" />
          </NextLink>
        }
        slot1={
          <NavSheet
            elementMain={
              <nav aria-label="Primary Navigation">
                <NavAccordion
                  activePath={activePath}
                  routes={routes.mobileOnly}
                  collapsible
                />
              </nav>
            }
            elementFooter={
              <nav
                aria-label="Secondary Navigation"
                className="flex flex-col gap-10px"
              >
                <LinkButton href={routes.MyTeam.path!}>
                  {routes.MyTeam.element}
                </LinkButton>
                <LinkButton
                  href={routes.SignOut.path!}
                  variant="outline"
                  palette="neutral"
                >
                  {routes.SignOut.element}
                </LinkButton>
                <div className="w-[249px]">
                  <SymbolSearch onSearchResultClick={({ detail }) => {
                    router.push(`/investing-tools/stocks/${encodeURIComponent(detail[0].identifier)}`)
                  }} />
                </div>
              </nav>
            }
          />
        }
        slot2={
          <nav
            aria-label="Secondary Navigation"
            className="flex items-center gap-10px"
          >
            {routes.DocumentsReceived?.path ? (
              <LinkDocumentsReceived route={routes.DocumentsReceived} />
            ) : null}
            <PopoverProfileAndSettings
              activePath={activePath}
              route={routes.ProfileAndSettings}
            />
          </nav>
        }
      />
      <HeaderWithSlots
        layout="desktopOnly"
        className="max-xl:hidden xl:grid"
        slot0={
          <NextLink href={routes.Home.path!}>
            <Logo layout="mark-and-word" size="sm" />
          </NextLink>
        }
        slot1={
          <NavMenu
            aria-label="Primary Navigation"
            activePath={activePath}
            routes={routes.desktopOnly}
          />
        }
        slot2={
          <nav
            aria-label="Secondary Navigation"
            className="flex items-center gap-20px"
          >
            <div className="w-[320px]">
              <SymbolSearch onSearchResultClick={({ detail }) => {
                router.push(`/investing-tools/stocks/${encodeURIComponent(detail[0].identifier)}`)
              }} />
            </div>
            <span className="flex items-center gap-10px">
              {routes.DocumentsReceived?.path ? (
                <LinkDocumentsReceived route={routes.DocumentsReceived} />
              ) : null}
              <PopoverProfileAndSettings
                activePath={activePath}
                route={routes.ProfileAndSettings}
              />
            </span>
            <span className="flex items-center gap-10px">
              <LinkButton href={routes.MyTeam.path!}>
                {routes.MyTeam.element}
              </LinkButton>
              <LinkButton
                href={routes.SignOut.path!}
                variant="outline"
                palette="neutral"
              >
                {routes.SignOut.element}
              </LinkButton>
            </span>
          </nav>
        }
      />
    </>
  );
}
